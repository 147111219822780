import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Title from '../atoms/Title'
import media from '../media'
import PrivateService from '../molecules/PrivateService'
import Section100h from '../organisms/Section100h'

const Root = styled(Section100h)`
  color: #222;
  color: ${props => props.color};
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: justify;
`

const PrivateArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  ${media.phone`
  grid-template-columns: 1fr;
  `}
`

export default () => {
  const data = useStaticQuery(graphql`
  query Business {
    site {
      siteMetadata {
        mainColor
      }
    }
  }
`)
  return (
    <Root id='business' color={data.site.siteMetadata.mainColor}>
      <Title>per aziende</Title>
      <PrivateArea>
        <PrivateService title='app native' description='Che la tua azienda sia nel settore B2B o nel B2C, se offre un accesso via app nativa il servizio non può che migliorare. Usando tecnologie per lo sviluppo di app ibride native, la manutenibilità è massima ed il costo ridotto.' />
        <PrivateService title='consulenza cloud' description='La tua azienda ha basato fino ad ora il suo business su un applicativo sviluppato con architettura monolitica? È il momento di passare al cloud. Con il cloud la tua applicazione diventa scalabile, resiliente, affidabile e manutenibile.' />
        <PrivateService title='sviluppo backend' description='Grazie alla conoscenza delle tecnologie più moderne ed affidabili, posso offrire alla tua azienda lo sviluppo di backend che si integrino al meglio con la piattaforma già a disposizione, o crearla da zero se serve.' />
        <PrivateService title='sviluppo frontend' description='Sono in grado di creare interfacce intuitive e responsive, utilizzando le più moderne tecnologie per massimizzare la manutenibilità futura e creare applicazioni web rapide chiavi in mano.' />
        <PrivateService title='design' description="Hai una startup e ti serve una consulenza per disegnare l'architettura del tuo progetto, che sia facile da implementare e flessibile per potersi adeguare alla crescita? AWS, IBM, Firebase, Azure... Ci basta scegliere insieme." />
      </PrivateArea>
    </Root>
  )
}
