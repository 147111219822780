import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Title from '../atoms/Title'
import media from '../media'
import PrivateService from '../molecules/PrivateService'
import Section100h from '../organisms/Section100h'

const Root = styled(Section100h)`
  color: #222;
  color: ${props => props.color};
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: justify;
`

const PrivateArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  ${media.phone`
  grid-template-columns: 1fr;
  `}
`

export default () => {
  const data = useStaticQuery(graphql`
  query Private {
    site {
      siteMetadata {
        mainColor
      }
    }
  }
`)
  return (
    <Root id='private' color={data.site.siteMetadata.mainColor}>
      <Title>per privati</Title>
      <PrivateArea>
        <PrivateService title='Sito Matrimonio' description="Rendi il Vostro giorno speciale ancora più speciale con un sito web dedicato al vostro evento, in cui gli invitati possano sempre trovare le informazioni necessarie in preparazione all'evento, dove tutti possano sentirsi più coinvolti e conoscere dei pezzetti in più della vostra storia." />
        <PrivateService title='Sito Portfolio' description='Se sei un professionista, o il proprietario di una piccola azienda, un sito web è necessario per aumentare e consolidare la tua presenza. Ti fideresti di un fornitore non rintracciabile su internet, o di un freelance che non ha un sito?' />
      </PrivateArea>
    </Root>
  )
}
