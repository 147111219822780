import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    // font-family: Open-Sans, Helvetica, Sans-Serif;
    font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
  }
  html,
  body {
    scroll-behavior: smooth;
  }
  section {
    padding: 1rem;
  }
  @-webkit-keyframes infiniteRotate {    
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes infinite-rotate {  
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes heart-pulse {
    0% {
      transform: scale(0.95);
    }
    50% {
      transform: scale(1.025);
    }
    70% {
      transform: scale(0.975);
    }
    100% {
      transform: scale(0.95);
    }
  }
`

export default GlobalStyle
