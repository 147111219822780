import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStaticQuery, Link, graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { atLeast } from '../media'
import { faBars } from '@fortawesome/free-solid-svg-icons'

const Header = styled.header`
  background-color: ${props => props.color};
  color: white;
  position: fixed;
  z-index: 2;
  top: 0;
  width: 100vw;
`

const SiteName = styled.div`
  margin: 0 auto;
  padding: 1.45rem 1.0875rem;
`

const SiteTitle = styled(Link)`
  background-color: ${props => props.color};
  color: white;
  box-shadow: none;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  letter-spacing: 4px;
  text-decoration: none;
  flex: 1;
`

const StyledLink = styled(Link)`
  background-color: ${props => props.color};
  color: white;
  box-shadow: none;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14pt;
  margin: 0 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
`

const Links = styled.h1`
  margin: auto;
  display: flex;
  overflow-x: auto;
  max-width: 100vw;
  padding: ${props => props.expandedLinks || !props.reducedHeader ? '0 1rem 1rem 1rem' : '0'};
  place-items: ${props => props.reducedHeader ? 'flex-end' : 'center'};
  flex-direction: ${props => props.reducedHeader ? 'column' : 'row'};
  max-height: ${props => props.expandedLinks || !props.reducedHeader ? '600px' : '0'};
  transition: max-height 0.5s linear 0s;
`
const Mark = styled.img`
  max-width: 3rem;
  ${atLeast.tablet`
    max-height: 4rem;
    max-width: 4rem;
  `}
  margin: 0 1rem;
`

const H1 = styled.h1`
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  place-items: 'center';
  padding-right: 1rem;
`

export default ({ siteTitle, reduced }) => {
  const data = useStaticQuery(graphql`
    query mainColorQuery {
      site {
        siteMetadata {
          mainColor
        }
      }
      mark: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "logo_mark_white.svg"}) {
        relativePath
        name
        extension
        publicURL
      }
    }
  `)
  const [reducedHeader, setReducedHeader] = useState(reduced)
  const [expandedLinks, setExpandedLinks] = useState(false)
  useEffect(() => {
    console.log('reducing header', reduced)
    setReducedHeader(reduced)
  }, [reduced])
  return (
    <Header color={data.site.siteMetadata.mainColor}>
      <SiteName>
        <H1>
          <Mark src={data.mark.publicURL} alt={data.mark.name} />
          <SiteTitle
            to='/'
            color={data.site.siteMetadata.mainColor}
          >
            {siteTitle}
          </SiteTitle>
          {reducedHeader && <FontAwesomeIcon icon={faBars} onClick={() => setExpandedLinks(v => !v)} />}
        </H1>
      </SiteName>
      <Links reducedHeader={reducedHeader} expandedLinks={expandedLinks} style={{ maxHeight: expandedLinks || !reducedHeader ? 500 : 0 }}>
        <StyledLink to='/#about' color={data.site.siteMetadata.mainColor}>about</StyledLink>
        <StyledLink to='/#private' color={data.site.siteMetadata.mainColor}>privati</StyledLink>
        <StyledLink to='/#business' color={data.site.siteMetadata.mainColor}>aziende</StyledLink>
        <StyledLink to='/#skills' color={data.site.siteMetadata.mainColor}>skills</StyledLink>
        {/* <StyledLink to='#contact' color={data.site.siteMetadata.mainColor}>contatti</StyledLink> */}
      </Links>
    </Header>
  )
}
