import React, { useRef, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Img from 'gatsby-image'
import styled from 'styled-components'
import Header from '../organisms/Header'
import media from '../media'
import useScrollPosition from '../atoms/scrollPosition'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const Badge = styled(Img)`
  width: 1rem;
  margin: auto 0;
  box-shadow: none;
`

const Layout = styled.div`
  margin: 0 auto;
  // padding: 0 1.0875rem 1.45rem;
  // max-width: 90vw;
  display: grid;
  grid-template-columns: 100%;
  // grid-template-rows: repeat(auto-fill, 100vh);
  scroll-snap-type: y proximity;
  overflow-y: scroll;
  @media (max-width: 960px) {
      max-width: 960px;
  }
  margin-top: 124px;
  ${media.phone`
    margin-top: 124px;
  `}
`

const Footer = styled.footer`
  // margin: 0 auto;
  // width: fit-content;
  // box-shadow: none;
  background-color: ${props => props.color};
  color: white;
  text-align: center;
  padding: 1rem 0;
`
const FooterContent = styled.div`
  margin: 0 auto;
  width: fit-content;
  background-color: ${props => props.color};
  color: white;
  padding: 1rem;
`

const SocialMark = ({ url, src }) => (
  <a
    href={url} target='_blank' rel='noopener noreferrer' style={{
      boxShadow: 'none',
      margin: '0 1rem 0 0'
    }}
  >
    <Badge fixed={src.childImageSharp.fixed} href={url} target='_blank' />
  </a>
)

export default ({ children }) => {
  const data = useStaticQuery(graphql`
    query Layout {
      site {
        siteMetadata {
          title
          mainColor
        }
      }
      linkedin: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "linkedin.png" }) {
        ...SocialMark
      }
      instagram: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "instagram.png" }) {
        ...SocialMark
      }
      github: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "github.png" }) {
        ...SocialMark
      }
      goodreads: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "goodreads.png" }) {
        ...SocialMark
      }
      mail: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "mail.png" }) {
        ...SocialMark
      }
    }
    fragment SocialMark on File {
      childImageSharp {
        fixed(width: 32) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  `)
  const [reducedOnScroll, setReducedOnScroll] = useState(false)
  const layoutRef = useRef()
  useScrollPosition(({ prevPos, currPos }) => {
    console.log('currPos', currPos)
    setReducedOnScroll(currPos.y < 0)
  }, [reducedOnScroll, layoutRef.current], layoutRef)
  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} reduced={reducedOnScroll} />
      <Layout ref={layoutRef}>
        {children}
        <Footer color={data.site.siteMetadata.mainColor}>
          <FooterContent>
            <SocialMark
              url='https://instagram.com/balsick'
              src={data.instagram}
            />
            <SocialMark
              url='https://www.goodreads.com/user/show/35009521'
              src={data.goodreads}
            />
            <SocialMark
              url='mailto:me@balsick.it'
              src={data.mail}
            />
            <SocialMark url='https://github.com/balsick' src={data.github} />
            <SocialMark
              url='https://www.linkedin.com/in/enrico-balsamo-40387754/'
              src={data.linkedin}
              style={{ marginRight: 0 }}
            />
          </FooterContent>
          © {new Date().getFullYear()} Enrico Balsamo
        </Footer>
      </Layout>
    </>
  )
}
