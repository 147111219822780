import React from 'react'
import Business from '../components/pages/Business'
import Layout from '../components/pages/Layout'
import Presentation from '../components/pages/Presentation'
import Private from '../components/pages/Private'
import Contact from '../components/pages/Contact'
import GlobalStyle from '../globalStyles'
import AboutMe from '../components/pages/AboutMe'
import Seo from '../components/organisms/Seo'
import Skills from '../components/pages/Skills'

const IndexPage = () => {
  return (
    <fragment>
      <GlobalStyle />
      <Layout>
        <Seo title='Home' />
        <Presentation />
        <AboutMe />
        <Private />
        <Business />
        <Skills />
        {/* <Contact /> */}
      </Layout>
    </fragment>
  )
}

export default IndexPage
