import React from 'react'
import { use100vh } from 'react-div-100vh'
import styled from 'styled-components'
import { atLeast } from '../media'

const Section = styled.section`
  ${atLeast.tablet`
    min-height: unset!important;
    height: unset!important;
  `}
`

export default (props) => {
  const minHeight = use100vh()
  return <Section {...{ ...props, fixed: undefined }} style={{ minHeight: minHeight * (props.percent || 1), height: (props.fixed && minHeight * (props.percent || 1)) || undefined }}>{props.children}</Section>
}
