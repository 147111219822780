import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Title from '../atoms/Title'
import Section100h from '../organisms/Section100h'

const Root = styled(Section100h)`
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: justify;
  color: ${props => props.color || '#222'};
`

const Highlight = styled.span`
  font-size: 2rem;
`

export default () => {
  const data = useStaticQuery(graphql`
  query AboutMe {
    site {
      siteMetadata {
        mainColor
      }
    }
  }
`)
  return (
    <Root id='aboutme'>
      <Title color={data.site.siteMetadata.mainColor}>ABOUT ME</Title>
      Ingegnere del Software con il pallino per la progettazione e lo sviluppo di architetture distribuite complesse,
      ma mantengo sempre un punto di vista pratico perché il software è soltanto uno strumento per rappresentare la realtà.
      Avendo accumulato anni di esperienza di lavoro in una startup, ho acquisito skill su numerose tecnologie, dal backend al frontend,
      in ambiti cloud, web, nativo, mantenendo il focus sulla <b>progettazione</b> e sullo <b>sviluppo</b>.
      {/* <br /><Highlight>Ma il pallino rimane l'architettura del software.</Highlight> */}
    </Root>
  )
}
