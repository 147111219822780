import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  color: ${props => props.color};
`

const Title = styled.h2`
  color: inherit;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.75rem;
`

const Divider = styled.hr`
  color: inherit;
  margin: 15px auto 25px;
  padding: 0;
  max-width: 250px;
  border: 0;
  border-top: solid 4px;
  box-sizing: content-box;
  height: 0;
`

export default (props) => {
  return (
    <Container {...props}>
      <Title>{props.children}</Title>
      <Divider />
    </Container>
  )
}
