import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Section100h from '../organisms/Section100h'
import Img from 'gatsby-image'
import Title from '../atoms/Title'
import { atLeast } from '../media'

const Root = styled(Section100h)`
  background-color: ${props => props.background};
  color: ${props => props.foreground};
  padding-top: 2rem;
  text-align: center;
  font-size: 1.5rem;
`

const ProfilePicture = styled(Img)`
  // max-width: 50%;
  // ${atLeast.tablet`
  //   max-height: 10rem;
  //   max-width: 10rem;
  // `}
  height: 75%;
  margin: auto;
  max-width: 50%;
  width: auto;
  aspect-ratio: 1;
  -webkit-animation: heart-pulse 5s infinite ease-out; /* Safari */
  animation: heart-pulse 5s infinite ease-out;
`
const Mark = styled.img`
  max-width: 50%;
  ${atLeast.tablet`
    max-height: 10rem;
    max-width: 10rem;
  `}
  margin: 0 auto;
  -webkit-animation: infiniteRotate 60s linear infinite; /* Safari */
  animation: infiniteRotate 60s linear infinite;
`

const StackDiv = styled.div`
  * {
    grid-area: full;
  }
  display: grid;
  grid-template-areas: 'full';
  height: 10rem;
`

export default () => {
  const data = useStaticQuery(graphql`
  query Presentation {
    site {
      siteMetadata {
        title
        mainColor
        onMainColor
      }
    }
    profile: file(
      sourceInstanceName: {eq: "images"}
      relativePath: {eq: "profile.png"}
    ) {
      ...ProfilePicture
    }
    mark: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "logo_mark_white.svg"}) {
      relativePath
      name
      extension
      publicURL
    }
  }
  fragment ProfilePicture on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
`)
  return (
    <Root id='about' background={data.site.siteMetadata.mainColor} foreground={data.site.siteMetadata.onMainColor} percent={0.5} fixed>
      <StackDiv>
        <Mark src={data.mark.publicURL} alt={data.mark.name} />
        <ProfilePicture fluid={data.profile.childImageSharp.fluid} alt='profilo' />
      </StackDiv>
      <Title color='white'>Enrico Balsamo</Title>
      Software Engineer
    </Root>
  )
}
