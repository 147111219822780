import { css } from 'styled-components'

const sizes = {
  tablet: 1024,
  smallTablet: 700,
  phone: 500,
  smallPhone: 375,
  iPhoneNew: [375, 812]
}

// Iterate through the sizes and create a media template
export default Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => Array.isArray(sizes[label])
    ? css`
      @media only screen and (width: ${sizes[label][0]}px) and (height: ${sizes[label][1]}px) {
        ${css(...args)}
      }
    `
    : css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export const atLeast = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `

  return acc
}, {})
