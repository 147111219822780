import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
`

const Title = styled.span`
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 2rem;
`

const Description = styled.span`
  font-size: 1.25rem;
  color: #222;
  line-height: 2rem;
`

export default (props) => {
  return (
    <Container>
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
    </Container>
  )
}
