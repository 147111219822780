import { useStaticQuery, graphql } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Title from '../atoms/Title'
import Section100h from '../organisms/Section100h'

const Root = styled(Section100h)`
  font-size: 1.25rem;
  line-height: 1.75rem;
  text-align: justify;
  color: ${props => props.color || '#222'};
`

const Container = styled.div`
  display: grid;
  place-items: center;
  grid-template-columns: repeat( auto-fit, minmax(150px, 1fr) );
  grid-column-gap: 1rem;
  > * {
    margin: 1em auto;
  }
`

const Skill = styled(Img)`
  max-width: 10rem;
  max-height: 10rem;
  > picture img {
    margin: auto;
    width: auto!important;
  }
`
const SkillSvg = styled.img`
  max-width: 10rem;
`

export default (props) => {
  const data = useStaticQuery(graphql`
  query skillImages {
    site {
      siteMetadata {
        mainColor
      }
    }
    allFile(sort: {fields: [name]}, filter: {sourceInstanceName: {eq: "images"}, relativePath: {regex: "/skills/[^_].*/"}}) {
        nodes {
          relativePath
          name
          extension
          publicURL
        }
      }
    docker: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "skills/docker.png"}) {
      ...SkillMark
    }
    java: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "skills/java.png"}) {
      ...SkillMark
    }
    maven: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "skills/maven.png"}) {
      ...SkillMark
    }
    postgresql: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "skills/postgresql.png"}) {
      ...SkillMark
    }
    react: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "skills/react.png"}) {
      ...SkillMark
    }
    spring: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "skills/spring.png"}) {
      ...SkillMark
    }
    reactnative: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "skills/react-native.png"}) {
      ...SkillMark
    }
    nodejs: file(sourceInstanceName: {eq: "images"}, relativePath: {eq: "skills/nodejs.png"}) {
      ...SkillMark
    }
  }
  
  fragment SkillMark on File {
    childImageSharp {
      fixed(width: 150) {
        ...GatsbyImageSharpFixed
      }
    }
  }  
`)
  return (
    <Root id='skills'>
      <Title color={data.site.siteMetadata.mainColor}>skills</Title>
      <Container>
        {data.allFile.nodes.map((elem, index) => {
          if (elem.extension === 'svg') {
            return (
              <SkillSvg key={elem.name + index} src={elem.publicURL} alt={elem.name} />
            )
          }
          console.log(elem)
          return (
            <Skill key={elem.name + index} fixed={data[elem.name.replace('-', '')].childImageSharp.fixed} alt={elem.name} />
          )
        }
        )}
      </Container>
    </Root>
  )
}
